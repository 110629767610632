import React, { useEffect, createContext, useContext, useRef, useState } from 'react';
import type { RudderAnalytics as RudderAnalyticsType } from '@rudderstack/analytics-js';
import { RudderAnalytics } from "@rudderstack/analytics-js";

// Create a context
export const RudderstackContext = createContext(null);

export const RudderstackProvider = ({ pageProps, children }) => {
    // const location = useLocation();
    const lastPathname = useRef(null);
    const [rudderstack, setRudderstack] = useState<RudderAnalyticsType>();

    // Setup Rudderstack
    useEffect(() => {
        // Initialize Rudderstack here
        // e.g., rudderanalytics.load(WRITE_KEY, DATA_PLANE_URL);

        // Identify (replace with your actual identify code)
        // e.g., rudderanalytics.identify('userId', { email: 'userEmail' });
        const initialize = async () => {
            if (!process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY) {
                console.log('[Rudderstack] no write key');
                return;
            }
            console.log('[Rudderstack] initializing', process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY);

            // const { RudderAnalytics } = await import('@rudderstack/analytics-js');
            const analyticsInstance = new RudderAnalytics();
            analyticsInstance.load(process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY, process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL);

            analyticsInstance.ready(() => {
                console.log('[Rudderstack] ready');

                // attach it to the window so we can use it in other places
                window.rudderanalytics = analyticsInstance;

                // save it
                setRudderstack(analyticsInstance);

                // identify the user

                // page view
                console.log(`[Rudderstack] page - initial page: ${pageProps?.data?.page?.internalName}`);
                analyticsInstance.page(pageProps?.data?.page?.internalName);
            });
        };


        initialize().catch(e => console.log(e));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Listen for location changes
    // useEffect(() => {
    //     if (location.pathname !== lastPathname.current && window.rudderanalytics) {
    //         // Track page visits (replace with your actual page tracking code)
    //         window.rudderanalytics.page();
    //         console.log('[Rudderstack] page', location.pathname);
    //
    //         // Update lastPathname ref to the current pathname
    //         lastPathname.current = location.pathname;
    //     }
    // }, [location]);

    // Rudderstack actions (you can add more methods or data to this context)
    const rudderstackActions = {
        // You can expose functions to identify, track, etc.
        rudderstack
    };

    return (
        <RudderstackContext.Provider value={rudderstackActions}>
            {children}
        </RudderstackContext.Provider>
    );
};

export const rudderTrack = (event, properties) => {
    console.log('[Rudderstack] track', event, properties);
    if (window.rudderanalytics) {
        // @ts-ignore
        window.rudderanalytics.track(event, properties);
    }
};

// Custom hook to use the Rudderstack context
export const useRudderstack = () => useContext(RudderstackContext);

export const useRudderStackAnalytics = () => {
    const { rudderstack } = useRudderstack();
    return rudderstack;
};
