/*
 * Google Analytics
 */

import { GOOGLE_ANALYTICS_GA4, GOOGLE_ANALYTICS_UA } from './defaults';

export const pushToDataLayer = (eventName: string, eventParams: any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: eventName, ...eventParams });
};

export const gaPageView = (url) => {
    if (window.gtag) {
        if (GOOGLE_ANALYTICS_UA) {
            // eslint-disable-next-line camelcase
            window.gtag('config', GOOGLE_ANALYTICS_UA, { page_path: url });
        }
        if (GOOGLE_ANALYTICS_GA4) {
            // eslint-disable-next-line camelcase
            window.gtag('config', GOOGLE_ANALYTICS_GA4, { page_path: url });
        }
    }
};

export const gaEvent = (action, params = {}) => {
    pushToDataLayer(action, params);
    if (window.gtag)
        window.gtag('event', action, params);
};
