import { createContext, ReactNode, useContext, useReducer } from 'react';

const UiStateContext: any = createContext(null);
const UiDispatchContext: any = createContext(null);

type UiProviderProps = {
    children?: ReactNode;
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_NAV_DRAWER':
            return {
                ...state, mobileNavDrawerVisible: true
            };
        case 'CLOSE_NAV_DRAWER':
            return {
                ...state, mobileNavDrawerVisible: false
            };
        case 'CLOSE_ALL':
            return {
                ...state, mobileNavDrawerVisible: false, modalVisible: false, playVideo: false, videoLink: ''
            };
        case 'OPEN_MODAL': {
            return {
                ...state, modalVisible: true, mobileNavDrawerVisible: false
            };
        }

        case 'PLAY_VIDEO': {
            return {
                ...state, playVideo: true, videoLink: action.payload
            };
        }

        case 'CLOSE_VIDEO': {
            return {
                ...state, playVideo: false, videoLink: ''
            };
        }

        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

export const UiProvider = ({ children }: UiProviderProps) => {
    const initialState = {
        mobileNavDrawerVisible: false, modalVisible: false, playVideo: false, videoLink: ''
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <UiDispatchContext.Provider value={dispatch}>
            <UiStateContext.Provider value={state}>
                {children}
            </UiStateContext.Provider>
        </UiDispatchContext.Provider>
    );
};

export const useUi = () => useContext(UiStateContext);
export const useDispatchUi = () => useContext(UiDispatchContext);
